.batch-status-green {
    color: #19aa8d;
}

.batch-status-grey {
    color: #aaaaaa;
}

.batch-status-red {
    color: #ed5565;
}

.batch-transaction-entry {
    color: #19aa8d;
}

.batch-transaction-exit {
    color: #ed5565;
}

.batch-details-panel-body,
.batch-details-panel-heading,
.batch-details-panel {
    border: none;
    background-color: #f7f7f7 !important;
}

.batch-details-panel-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.batch-details-panel-title-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 85%;
}

.batch-details-header-row {
    padding: 15px;
    align-items: center;
    display: flow-root;
}

.batch-details-header-ibox-tools {
    padding-top: 4px;
}

.batch-details-identifier {
    font-size: 18px;
    font-weight: bolder;
    margin: 0 0 0 10px !important;
}

.batch-details-production-class {
    font-size: 18px;
    font-weight: normal;
    font-style: italic;
    margin-left: 10px;
}

.batch-details-date {
    font-size: 18px;
    margin: 0 0 0 10px;
}

.batch-details-tab-header-7 {
    width: calc(100% / 7);
    text-align: center;
    font-size: 18px;
}

.batch-details-tab-header-6 {
    width: calc(100% / 6);
    text-align: center;
    font-size: 18px;
}

.batch-details-tab-header-5 {
    width: calc(100% / 5);
    text-align: center;
    font-size: 18px;
}

@media (max-width: 768px) {
    .batch-details-panel-title-info,
    .batch-details-identifier {
        margin-bottom: 10px !important;
        justify-content: center;
    }

    .batch-details-tab-header-5,
    .batch-details-tab-header-6 {
        width: unset;
    }

    .batch-details-header-row {
        padding: 0;
    }
}

@media (max-width: 1100px) {
    .batch-details-tab-header-7 {
        width: 100%;
    }

    .batch-details-panel-title-info {
        margin-bottom: 10px !important;
        align-items: flex-start;
        align-content: flex-start;
        flex-direction: column;
    }

    .batch-details-identifier {
        margin: 10px 0!important;
    }

    .batch-details-date {
        margin: 10px 0;
    }
}

.batch-details-tab-header > a {
    margin-right: 0 !important;
}

.batch-details-panel-element {
    background-color: #fafafa;
    border-style: solid;
    border-color: #f3f3f4;
    border-width: 2px 1px;
}

.batch-details-panel-element label{
    font-size: 14px;
}

.batch-details-panel-element-divider label{
    width: 100%;
    padding-right: 0;
}

.batch-details-panel-element .text-field-info{
    padding: 0;
}

.fa-openEventsCounter {
    position: relative;
    text-align:center;
    vertical-align:middle;
    margin-left: 5px;
}

.transaction-status-done {
    height: 16px;
    max-width: 120px;
    text-align: center;
    border-radius: 8px;
    background-color: rgb(8, 112, 26);
    color: #f9f9f9;
    font-size: 10px;
}

.transaction-status-pending {
    height: 16px;
    max-width: 120px;
    text-align: center;
    border-radius: 8px;
    background-color: rgb(206, 167, 40);
    color: #f9f9f9;
    font-size: 10px;
}
.ibox-content-carousel {
    background-color: #ffffff;
    color: inherit;
    padding: 15px 20px 20px 20px;
    border-color: #e7eaec;
    border-image: none;
    border-style: none;
    border-width: 1px 0;
    height: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.carousel-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.item{
    padding-bottom: 2.5rem;
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.item-img {
    height: 200px;
    display: flex;
    justify-content: center ;
}

.item p {
    padding: 1rem 0 0 1rem;
    left: 0;
    right: 0;
    bottom:0;
    text-align: center;
    color: rgb(103, 106, 108);
}

.carousel-indicators {
    height: 0;
}

.carousel-indicators li {
    background-color: grey;
}

.carousel-indicators li.active {
    background-color: black;
}

.carousel-control {
    background-color: #ffffff;
    color: grey;
    background-image: linear-gradient(to right,rgba(255,255,255,.5) 0,rgba(255,255,255,.0001) 100%) !important;
}

.carousel-control:focus, .carousel-control:hover {
    color: grey;
    text-decoration: none;
    filter: alpha(opacity=90);
    outline: 0;
    opacity: .9;
}

.multifeed-transaction-td-vertical-align-middle {
    vertical-align: middle !important;
}

@media (max-width: 998px) {
    .multifeed-transaction-td-vertical-align-middle {
        overflow: visible;
        white-space: unset;
        text-overflow: clip;
        vertical-align: middle !important;
        overflow-wrap: break-word;
    }
}

.multifeed-transaction-td-left-margin {
    text-align: center !important;
}

.align-batch-details {
    display: flex;
}

.manual-weighing-form {
    padding: 20px;
    background-color: white;
    z-index: 4;
}

.manual-weighing-form-top-menu {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.feed-factory-map-label {
    display: flex;
    align-items: center;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 0;
}

.batch-kpi-box {
    display: flex;
    justify-content: space-between;
}

.batch-kpi {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 20px;
    height: 50px;
}

.batch-kpi-helper {
    font-size: xx-small;
}

.batch-action-div {
    padding: 1em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.batch-action-btn {
    padding: 10px 20px !important;
    border-right-style: solid !important;
    border-right-width: 1px !important;
    background-color: #1c84c6 !important;
    border-color: #1c84c6 !important;
    color: #FFFFFF !important;
}

.active.batch-tab-header{
    border: none !important;
    border-bottom: 3px solid #84C13E !important;
}

.active.batch-tab-header > span {
    background-color: inherit !important;
    border: none !important;
}

.batch-tab-panel > div > ul li {
    display: list-item;
}

.batch-tab-panel > div > .tab-content {
    border: none;
}

.batch-tab-panel > div > .nav-tabs {
    border: none;
}

@media(max-width: 960px) {
    .align-batch-details {
        flex-wrap: wrap;
    }
}