.tree-sector {
  border: none;
  background-color: white;
  box-shadow: 0 0 10px 0 hsla(0,0%,60.8%,.3);

  .tree-sector-heading {
    background-color: white;

    .tree-sector-title {
      margin: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tree-sector-type {
        font-style: italic;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .tree-sector-body {
    background-color: #dcdcdc;
  }

}

.tree-category {
  border: none;
  background-color: #f7f7f7;
  box-shadow: 0 0 10px 0 hsla(0,0%,60.8%,.3);

  .tree-category-heading {
    border: none;
    background-color: white;

    .tree-category-title {
      margin: 0 0 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin-bottom: 0;
      }
    }
  }

  .tree-category-body {
    background-color: white;
    padding: 0 40px;
    overflow-x: auto;
  }
}

.tree-view-table {

  td {
    border: 0 !important;
    word-break: break-word;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    img {
      margin-bottom: 5px;
      margin-top: 5px;
    }

  }
}

.tree-arrow-direction {
  transform: rotate(-90deg);
  transition-duration: 500ms;

  &.up {
    transform: rotate(90deg);
    transition-duration: 500ms;
  }

}

.opacity-08 {
  opacity: 0.2;
}

