.valigned {
    min-height: 100%;
    display: flex;
    align-items: center;
}

.c-green {
    color: #92BE53;
}

.c-orange {
    color: #FF9E14;
}

.c-red {
    color: #ed5565;
}

.c-blue {
    color: #1976D2;
}

.c-gray {
    color: #676a6c;
}

.c-darkGray {
    color: #888888;
}

.c-black {
    color: #000000;
}

.bg-green {
    background: #92BE53;
}

.bg-orange {
    background: #FF9E14;
}

.bg-red {
    background: #ed5565;
}

.bg-blue {
    background: #337ab7;
}

.bg-gray {
    background: #676a6c;
}

.bg-darkGray {
    background: #888888;
}

.bg-lightGray {
    background: rgba(216, 216, 216, 0.12);
}

.bg-black {
    background: #000000;
}

.wp-initial{
    white-space: initial;
}

.c-white {
    color: white;
}

.bg-white {
    background-color: #FFFFFF;
}

.bgc-stickyNote {
    background-color: #FCFAD3;
}

.f-size-12 {
    font-size: 12px;
}

.f-size-14 {
    font-size: 14px;
}

.f-size-16 {
    font-size: 16px;
}

.f-size-18 {
    font-size: 18px;
}

.z-index-4 {
    z-index: 4;
}

.z-index-10 {
    z-index: 10;
}

.ml-auto {
    margin-left: auto;
}

.ml-0{
    margin-left: 0!important;
}

.mr-7 {
    margin-right: 7px;
}

.ml-10{
    margin-left: 10px;
}

.ml-12{
    margin-left: 12px;
}

.ml-30{
    margin-left: 30px;
}

.ml-13{
    margin-left: 13px;
}

.ml-15{
    margin-left: 15px;
}

.ml-0 {
    margin-left: 0;
}

.ml-2 {
    margin-left: 4px;
}

.ml-3 {
    margin-left: 3px;
}

.ml-4 {
    margin-left: 4px;
}

.ml-5 {
    margin-left: 5px;
}

.ml-6 {
    margin-left: 6px;
}

.ml-8 {
    margin-left: 8px;
}

.margin-left50{
    margin-left: 50px;
}

.mb-35{
    margin-bottom: 35px;
}

.mb-20{
    margin-bottom: 20px;
}

.mt-minus-5 {
    margin-top: -5px;
}

.mt-zero-absolute {
    margin-top: 0px !important;
}

.mt-0 {
    margin-top: 0px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-21 {
    margin-top: 21px;
}

.mt-23 {
    margin-top: 23px;
}

.mt-25 {
    margin-top: 25px;
}

.mb-zero-absolute {
    margin-bottom: 0 !important;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mr-0 {
    margin-right: 0;
}

.mr-2 {
    margin-right: 2px;
}

.mr-5 {
    margin-right: 5px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-12 {
    margin-right: 12px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-40 {
    margin-right: 40px !important;
}

.mr-5 {
    margin-right: 5px;
}

.ml-2 {
    margin-left: 2px;
}

.m-total-0 {
    margin: 0;
}

.ml-total-15 {
    margin: 15px;
}

.ml-total-30 {
    margin: 30px;
}

.pd-l-15{
    padding-left: 15px;
}
.pd-0 {
    padding: 0;
}

.pd-5 {
    padding: 5px;
}

.pd-10 {
    padding: 10px;
}

.pd-15 {
    padding: 15px;
}

.pd-20 {
    padding: 20px;
}

.pd-r-0{
    padding-right: 0;
}
.pd-r-0-important{
    padding-right: 0 !important;
}

.pd-r-10{
    padding-right: 10px;
}

.pd-r-15{
    padding-right: 15px;
}

.pd-r-25{
    padding-right: 25px;
}

.pd-r-20{
    padding-right: 20px;
}

.pd-l-20{
    padding-left: 20px;
}

.pd-l-10{
    padding-left: 10px;
}

.pd-l-4{
    padding-left: 4px;
}

.m-r-25 {
    margin-right: 25px;
}

.m-auto{
    margin: auto;
}

.p-left-0 {
    padding-left: 0;
}

.p-total-0{
    padding: 0;
}

.p-l-r-0{
    padding-left: 0;
    padding-right: 0;
}

.p-total-20{
    padding: 20px;
}

.pd-r-15{
    padding-right: 15px;
}

.pd-r-5{
    padding-right: 5px;
}

.pd-t-0{
    padding-top: 0;
}

.pd-t-5{
    padding-top: 5px;
}

.pd-t-7 {
    padding-top: 7px;
}

.pd-t-10{
    padding-top: 10px;
}

.pd-t-12{
    padding-top: 12px;
}

.pd-t-15{
    padding-top: 15px;
}

.pd-t-20{
    padding-top: 20px;
}

.pd-t-23 {
    padding-top: 23px;
}

.pd-t-30{
    padding-top: 30px;
}

.pd-t-50{
    padding-top: 50px;
}

.pd-b-15{
    padding-bottom: 15px;
}

.pd-b-10{
    padding-bottom: 10px;
}

.pd-b-0 {
    padding-bottom: 0 !important;
}

.pd-b-2 {
    padding-bottom: 2px;
}

.pd-b-20 {
    padding-bottom: 20px;
}

.my-20 {
    margin: 20px 0;
}

.my-40 {
    margin: 40px 0;
}

.mx-15 {
    margin-left: 15px;
    margin-right: 15px;
}

.mx-20 {
    margin: 0 20px;
}

.optional {
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
    text-align: right;
    color: #676a6c;
}

.height-34em {
    height: 34em
}

.max-height-34em {
    max-height: 34em
}

.max-height-29em {
    max-height: 29em !important
}

.height-29em {
    height: 29em !important
}

.width-101pc {
    width: 101%
}

.fixed-thead, .fixed-tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.fixed-tbody {
    display: block;
    table-layout: fixed;
    max-height: 29em;
}

@media (min-width: 200px)
and (max-width: 768px) {
    .user-top-right-menu {
        float: right;
    }

    .user-top-right-menu > .dropdown > .dropdown-menu-button {
        margin-top: 12px;
        margin-right: 2vh;
    }

    ul.dropdown-menu {
        right: 0 !important;
        left: auto;
        margin-right: 5px;
    }
}

@media (min-width: 768px) {
    #page-wrapper {
        position: inherit;
        margin: 0 0 0 220px;
        min-height: stretch;
    }
}

/* Both Portrait and Landscape */
@media only screen
and (min-device-width: 200px)
and (max-device-width: 768px) {
    #page-wrapper {
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
        margin-left: 0;
        min-height: stretch;
    }

    .no-pdlr-mlr {
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
        margin-left: 0;
    }

    .minimalize-styl-2 {
        padding: 4px 12px;
        margin: 14px 5px 5px 14px;
        font-size: 14px;
        float: left;
    }

    .wrapper-content {
        padding-left: 0;
        padding-right: 0;
    }

    .user-top-right-menu {
        float: right;
    }

    .user-top-right-menu > .dropdown > .dropdown-menu-button {
        margin-top: 12px;
        margin-right: 2vh;
    }

    ul.dropdown-menu {
        right: 0 !important;
        left: auto;
        margin-right: 5px;
    }

    .xs-sm-ta-center {
        text-align: center !important;
    }

    .table-pad-xs-sm {
        padding: 15px 0;
    }

    .ml-8-xs-sm {
        margin-left: 8px;
    }

    .add-cycle-modal {
        width: 100% !important;
        left: 0 !important;
    }

    .fc-modal {
        width: 100%;
        left: 0;
    }
}

/* Portrait */
@media only screen
and (min-device-width: 200px)
and (max-device-width: 768px)
and (orientation: portrait) {
    .xs-sm-ta-center-portrait {
        text-align: center !important;
    }
    .show-icon {
        display: none!important;
    }
    .hidden-severity-ellipse {
        display: none!important;
    }
    .display-severity-circle {
        display: inline-block!important;
    }
}

/* Landscape */
@media only screen
and (min-device-width: 200px)
and (max-device-width: 768px)
and (orientation: landscape) {
    .show-icon {
        width: 9%!important;
    }
}

@media only screen
and (min-device-width: 200px)
and (orientation: landscape) {
    .display-severity-circle {
        display: none!important;
    }
}

div.left-header-logo {
    display: inline-block!important;
    margin: 19px 0 19px 8px;
}

img.left-header-logo {
    height: 25px;
}

input.clear-field-input[type="search"] {
    -webkit-appearance: searchfield!important;
}

input.clear-field-input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 17px;
    cursor: pointer;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: right;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-input-group {
    display: inline-flex;
    justify-content: center;
}

.flex-input-group-addon {
    width:40px;
    display: flex;
    justify-content: center;
}

.flex-align-self-center {
    align-self: center;
}

.active-tab {
    background-color: #ffffff;
}

.m-negative-8{
    margin: -8px;
}

.f-bold{
    font-weight: bold;
}

.icon-with-anchor {
    text-decoration: none;
    color: #676a6c;
}


.react-bs-table {
    border:none !important;
}

.react-bs-table table td, .react-bs-table table th {
    overflow: visible;
}

.users-table-status-active{
    font-weight: bold;
    color: #19aa8d;
}

.users-table-status-deactivated{
    font-weight: bold;
    color: #ed5565;
}

.user-list-mail-icon{
    color: #000000;
}

.FarmLogo {
    width: 300px;
    margin-bottom: 60px;
    object-fit: contain;
}

.pt2020 {
    width: 300px;
    margin-top: 10px;
    object-fit: contain;
}

.pt2020-footer {
    width: 200px;
    margin-right: 10px;
    margin-top: 10px;
    object-fit: contain;
}

@media (max-width: 768px) {
    .pt2020-footer {
        margin-bottom: 10px;
    }
}

.user-form .row-buffer{
    margin-top:20px
}

.user-form .row-buffer-plus{
    margin-top:40px
}

.create-user-avatar-placeholder{
    font-size: 140px;
}

.user-avatar-icon{
    font-size: 64px;
}

.vertically-center {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.valign-middle {
    vertical-align: middle !important;
}

/*react selection tree styles*/
.tree-selector{
    background-color: #FFFFFF;
    margin: 2px;
    font-size: 13px;
}

.react-checkbox-tree ol {
    margin: 0;
    padding-left: 0;
    list-style-type: none;
}
.react-checkbox-tree ol ol {
    padding-left: 24px;
}
.react-checkbox-tree button {
    line-height: normal;
    color: inherit;
}
.react-checkbox-tree button:focus {
    outline: none;
}
.react-checkbox-tree label {
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    font-size: 14px;
}
.react-checkbox-tree label:hover {
    background: rgba(51, 51, 204, 0.1);
}
.react-checkbox-tree input {
    display: none;
}
.react-checkbox-tree .rct-icon {
    font-family: "FontAwesome";
    font-style: normal;
}

.rct-collapse,
.rct-node-icon,
.rct-checkbox {
    padding: 0 5px;
}
.rct-collapse *,
.rct-node-icon *,
.rct-checkbox * {
    display: inline-block;
    margin: 0;
    width: 14px;
}

.rct-text {
    display: inline-block;
    align-items: center;
}

.rct-collapse {
    border: 0;
    background: none;
    line-height: normal;
    color: inherit;
    font-size: 12px;
}
.rct-collapse.rct-collapse-btn {
    cursor: pointer;
}
.rct-collapse > .rct-icon-expand-close {
    opacity: .5;
}
.rct-collapse > .rct-icon-expand-close:hover {
    opacity: 1;
}

.rct-node-icon {
    color: #33c;
    display: none;
}

.rct-title {
    padding: 0 5px;
}

.rct-icon-expand-close::before {
    content: "\f054";
}

.rct-icon-expand-open::before {
    content: "\f078";
}

.rct-icon-uncheck::before {
    content: "\f096";
}

.rct-icon-check::before {
    content: "\f046";
}

.rct-icon-half-check::before {
    opacity: .5;
    content: "\f046";
}

.rct-icon-leaf::before {
    content: "\f016";
}

.rct-icon-parent-open::before {
    content: "\f115";
}

.rct-icon-parent-close::before {
    content: "\f114";
}

input[readonly].form-control{
    background-color:#FFFFFF;
}

.required-field:after {
    color: #d00;
    content: "*";
    position: absolute;
}

.forgot-password-label {
    font-size: 16px;
}

.has-feedback .form-control {
    padding-right: 34px;
}

.has-feedback .form-control.input-sm,
.has-feedback.form-group-sm .form-control {
    padding-right: 30px;
}
.has-feedback .form-control.input-lg,
.has-feedback.form-group-lg .form-control {
    padding-right: 46px;
}
.has-feedback-left .form-control {
    padding-right: 12px;
    padding-left: 34px;
}
.has-feedback-left .form-control.input-sm,
.has-feedback-left.form-group-sm .form-control {
    padding-left: 30px;
}
.has-feedback-left .form-control.input-lg,
.has-feedback-left.form-group-lg .form-control {
    padding-left: 46px;
}
.has-feedback-left .form-control-feedback {
    left: 0;
}
.form-control-feedback {
    line-height: 34px !important;
}
.input-sm + .form-control-feedback,
.form-horizontal .form-group-sm .form-control-feedback {
    width: 30px;
    height: 30px;
    line-height: 30px !important;
}
.input-lg + .form-control-feedback,
.form-horizontal .form-group-lg .form-control-feedback {
    width: 46px;
    height: 46px;
    line-height: 46px !important;
}
.has-feedback label.sr-only ~ .form-control-feedback,
.has-feedback label.sr-only ~ div .form-control-feedback {
    top: 0;
}
@media (min-width: 768px) {
    .form-inline .inline-feedback {
        position: relative;
        display: inline-block;
    }
    .form-inline .has-feedback .form-control-feedback {
        top: 0;
    }
}
.form-horizontal .has-feedback-left .form-control-feedback {
    left: 15px;
}

.show-hide-password {
    float: right;
    margin-right: 15px;
    margin-top: -24px;
    font-size: 10px;
    color: #4988be;
}

.has-feedback label~.form-control-feedback {
    top: 28px;
}

.sweet-alert{
    height: 350px;
}

.avatar-placeholder{
    width: 144px;
    height: 144px;
}

.upload-photo-placeholder{
    width: 144px;
    height: 144px;
}

.avatar-form-preview{
    width: 144px;
    height: 144px;
}

#avatar-picture:hover{
    opacity: 0.2;
    cursor: pointer;
}

.upload-photo-placeholder:hover{
    opacity: 0.6;
    cursor: pointer;
}

.open-form-button a{
    font-size: 14.2px;
    font-weight: bold;
}

.open-form-button span{
    font-size: 14.2px;
    font-weight: bold;
    cursor: pointer;
}

.opened-form-button{
    font-size: 14.2px;
    font-weight: bold;
    color: #9e9e9e;
}

.create-form-button{
    margin-bottom: 12px;
    position: relative;
}

.form-header{
    color: #1564C0;
}

.btn-bold{
    font-weight: bold;
}

.btn-size{
    margin-left: 3px;
    margin-right: 3px;
}

.fav-entities-selector > .btn-bold{
    background-color: #DDDDDD;
}

.reset-password-form-button{
    background-color: #676A6C;
    color: white
}

.reset-password-form-button:hover{
    background-color: #929393;
    color: #f2f2f2
}

.reset-password-form-button:focus{
    background-color: #676A6C;
    color: white
}

.reset-password-form-button:focus:hover{
    background-color: #929393;
    color: #f2f2f2
}

.toggle-button{
    color: white;
    outline: none !important;
    text-decoration: white;
    border-radius: 15px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
    width: 18%;
    font-size: 14px;
    white-space: normal;
}

.toggle-button:hover{
    background-color: #929393;
    outline: none !important;
    color: #f2f2f2;
}

.toggle-button:focus:hover{
    background-color: #929393;
    color: white;
}
.toggle-button:focus{
    outline: white !important;
    color: white;
}

.toggle-button-clicked{
    outline: white !important;
    background-color: #676A6C;
    color: white;
    border-radius: 15px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
    font-weight: bold;
    width: 18%;
}

.toggle-button-clicked:focus:hover{
    background-color: #929393;
    color: #f2f2f2
}
.toggle-button-clicked:focus{
    outline: white !important;
    color: #f2f2f2
}

.toggle-group {
    text-align: center;
}

.users-table-action-icons i{
    color: #000000;
}

.blocked-user-action{
    cursor: default;
    color: #c5c5c5;
}

.searchbox{
    margin: 0 15px;
}

.list-searchbox-row{
    background-color: #EEEFF0;
    padding-left: 15px;
}

.btn-buffer{
    margin-left: 5px;
    margin-right: 5px;
}

.btn-grey {
    background-color: #676A6C;
    color: #FFFFFF;
}

.btn-light-grey {
    background-color: #EDEEEF !important;
}

.btn-grey:hover{
    background-color: #929393;
    color: #f2f2f2
}

.btn-grey:focus{
    background-color: #676A6C;
    color: #FFFFFF
}

.user-form-buttons{
    display: flex;
    justify-content: flex-end;
}

.status-label{
    font-weight: normal;
}

.blocked-content{
    position:absolute;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    background-color:rgba(255,255,255, 0.8);
    z-index: 3;
}

.btn-group button {
    background-color: white;
    border: 1px solid #e7eaec;
    color: #676a6c;
    padding: 8px 0;
    cursor: pointer;
    float: left;
}

@media (min-width: 200px)
and (max-width: 768px) {
    .btn-group button {
        background-color: white;
        border: 1px solid #e7eaec;
        color: #676a6c;
        padding: 8px 0;
        padding: 8px;
        height: 100%;
        cursor: pointer;
        float: left;
    }
}

.btn-group {
    padding-left: 0;
    padding-right: 0;
}

.btn-group:after {
    content: "";
    clear: both;
    display: table;
}

.btn-group button:not(:last-child) {
    border-right: none;
}

.btn-group-edit button:hover {
    border: 1px solid #1564C0;
}

button.btn-clicked {
    background:#1976D2;
    color: #f9f9f9;
}

button.btn-disabled {
    background:#eee;
}

.text-field-info{
    padding: 5px 0px;
    font-size: 14px;
    white-space: normal;
    word-wrap: break-word;
}

.blocked-input-group-addon {
    color: #c2c2c2;
    background-color: #eeeeee;
    cursor: not-allowed;
}

.navbar-fixed-top,
.navbar-static-top {
    background: #e2e2e2;
}

body.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
    font-size: 12px;
    border-radius: 3px;
    width: 200px;
}

.nav-header{
    background-image: none;
    background-color: #FFFFFF;
    padding: 0;
}

body.mini-navbar .nav-header,
.nav.nav-second-level.collapse.in,
.nav.nav-second-level.collapsing {
    background-color: #171F21;
}

.logo-element{
    height: 60px;
}

body.mini-navbar .profile-element {
    display: flex !important;
}

.navigation-img{
    height: 40px;
    margin-top: 11px;
    margin-bottom: 10px;
}

.navigation-img-wrapper {
    height: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.left-header-logo {
    display: none;
}

.sidebar-collapse > .nav > li.active {
    background-color: #171f21;
    border-left: 4px solid #84C13E;
}

.sidebar-collapse > div > div > .nav > li.active {
    background-color: #171f21;
    border-left: 4px solid #84C13E;
}

.navbar-default .nav > li > a:hover :not(.collapsed-second-third-level),
.navbar-default .nav > li > a:focus,
.nav-second-level li:hover :not(.collapsed-second-third-level),
.nav-third-level li:hover, .nav-second-level li.active :not(collapsed-second-third-level),
.add-dashboard:hover {
    background-color: transparent;
}

.navbar-default .nav-second-level li:hover :not(.collapsed-second-third-level),
.navbar-default .nav-second-level li.active :not(.collapsed-second-third-level),
.navbar-default .nav-second-level li > a:hover :not(.collapsed-second-third-level),
.navbar-default .nav-second-level li > a:active,
.add-dashboard-label:hover {
    background-color: transparent;
    color: white;
}

.navbar-default .nav-second-level li.active > .nav-third-level :not(.collapsed-second-third-level) {
    background-color: transparent;
    color: #a7b1c2 !important;
}

.navbar-default .nav-second-level li.active > .nav-third-level li.active :not(.collapsed-second-third-level) {
    background-color: transparent;
    color: white !important;
}

.sidebar-collapse .nav>li.active, ul.nav-second-level {
    background-color: #171f21;
}

.sidebar-collapse > div > div > .nav>li.active, ul.nav-second-level {
    background-color: #171f21;
}

.navbar-static-top {
    z-index: 8;
}

/* row with spacing */
.spaced-row {
    margin-top: 10px;
    margin-bottom: 10px;
}

/* fix Bootstrap Table pagination misalignment */
ul.react-bootstrap-table-page-btns-ul.pagination {
    margin-top: 0px;
}

.ibox-border-no-border-top {
    border-top: 0;
}

.labeled-field-helper{
    width: 150px;
    text-align: center;
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    height: 34px;
    display: flex;
    justify-content:center;
    flex-direction:column;
}

.checkbox-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: space-between;
}

.checkbox-tree-error{
    color: #a94442;
    font-style: italic;
}

.tabs-container .tab-pane .panel-body{
    border: none;
}

.tabs-container .tabs-left > .nav-tabs > li > a {
    border-radius: 0px !important;
}

.dropdown-menu-button{
    padding: 4px 12px;
    margin: 14px 5px 9px 20px;
    font-size: 14px;
    float: right;
}

.rdt input[disabled]{
    background-color: #eee;
    opacity: 1;
}

.simple-field {
    border: none;
    display: block;
    margin: 0;
    width: 37%;
    font-family: sans-serif;
    box-shadow: none;
    border-radius: 0;
    background-color: inherit;
    padding-left: 5px;
    line-height: 1;
    font-weight: bold;
    font-size: 20px;
    float: left;
    text-align: right;
}

.simple-field-edit {
    border-bottom: solid 1px #FFFFFF;
    border-spacing: inherit;
    transition: border 0.3s;
}

.simple-field-edit:focus,
.simple-field-edit.focus {
    border-bottom: solid 1px #FFFFFF;
    outline: none;
}

.labeled-field-dropdown {
    text-align: left;
}

.labeled-field-dropdown span {
    margin-left: 0;
}

.labeled-field-dropdown .Select-control {
    border-radius: 0;
    border: 1px solid #e5e6e7;
}

.labeled-field-dropdown .Select-menu-outer {
    z-index: 10;
}

.Select--multi > .Select-menu-outer{
    z-index: 10;
}

.labeled-field-helper-icon {
    height: 17px;
    cursor: pointer;
}

.align-icon-horizontal {
    display: table;
    margin: 0 auto;
}

/** Button outline disabled fix */
.btn.btn-outline.disabled {
    background-color: transparent;
}
.btn.btn-outline.disabled:hover,
.btn.btn-outline.disabled:focus,
.btn.btn-outline.disabled:active {
    color: #1ab394;
}


/** New helper class */
.p-w-xxs {
    padding: 0 5px;
}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-d-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-1 {
    flex: 1;
}

.flex-wrap{
    flex-wrap: wrap;
}

.justify-between{
    justify-content: space-between;
}

.justify-center{
    justify-content: center;
}

.justify-end{
    justify-content: flex-end;
}

.justify-start{
    justify-content: flex-start;
}

.justify-around{
    justify-content: space-around;
}

.align-items-center{
    align-items: center;
}

.align-items-end{
    align-items: flex-end;
}

.align-items-baseline{
    align-items: baseline;
}

.fc-modal {
    outline: none;
    border-radius: 5px;
    background-color: #FFFFFF;
    -ms-transform: translate(-50%, -50%);
    display: block;
    top: 10%;
    position: absolute;
    margin-bottom: 40px;
}

.add-widget-modal {
    width: 80%;
    left: 10%;
}

.task-details-modal {
    width: 80%;
    left: 10%;
}

.loading-modal {
    width: 80%;
    left: 10%;
    height: 80%;
    display: table;
}

.add-cycle-modal {
    width: 30%;
    left: 35%;
}

.transfer-entity-modal{
    width: 40%;
    left: 30%;
}

.tariff-clone-modal{
    width: 40%;
    left: 30%;
}

.entity-map-modal{
    width: 70%;
    left: 15%;
}

.ReactModal__Overlay{
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 1059;
    overflow: auto;
}

.user-top-right-menu > .open > button > span > .fa-angle-down:before {
    content: "\F106" !important;
}
li.nav-header {
    background-color: #152630;
}

.navbar-default {
    background-color: #152630;
}

.theme-config {
    top: 76px !important;
}

.gap-10 {
    gap: 10px;
}

.gap-20 {
    gap: 20px;
}

.gap-25 {
    gap: 25px;
}

body {
    background-color: #152630;
}

.btn-blue {
    background-color: #49B9E8 !important;
    border-color: #49B9E8 !important;
    color: #ffffff !important;
}

.btn-blue:hover, .btn-blue:focus, .btn-blue:active,
.btn-blue.active, .open .dropdown-toggle.btn-blue,
.btn-blue:active:focus, .btn-blue:active:hover,
.btn-blue.active:hover, .btn-blue.active:focus {
    background-color: #3b9cc2 !important;
    border-color: #3b9cc2 !important;
}

.menu-item-username, .menu-item-username > a {
    margin-top: 0px !important;
    background-color: #e0dcdc;
}

@supports (-ms-ime-align: auto) {
    /* Microsoft EdgeV13&14 CSS styles go here */
    .navbar-header{
        display: flex;
    }

    .navbar-minimalize {
        height: 32px;
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    /* Safari-Opera specific declarations here */
    .navbar-header{
        display: flex;
    }

    .navbar-minimalize {
        height: 32px;
    }
}

.removeTopBorder > div > div > div > .panel-body {
    border: 0 !important;
}

.swal2-popup {
    font-size: medium !important;
}

.fa-rotate-180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.rotate--90 {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}


.background-color-white {
    background-color: white;
}

.tab-content {
    /*border-left: 1px solid rgb(231, 234, 236);*/
    /*border-right: 1px solid rgb(231, 234, 236);*/
    /*border-bottom: 1px solid rgb(231, 234, 236);*/
}

.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

.word-break-normal-important {
    word-break: normal !important;
    overflow: visible;
    z-index: 999;
}

@media (max-width: 768px) {
    #navbar-Alert, #navbar-Rules, #navbar-Tasks {
        display: none;
    }
}

.fill-available {
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    /* autoprefixer: off */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill;
}

.display-none {
    display: none !important;
}

.clickable-icon-color {
    color: #1976D2;
    text-decoration: none;
    cursor: pointer;
}

.topheader-span {
    color: #999c9e;
    font-size: 14px;
    padding: 20px 10px;
    min-height: 50px;
    font-weight: 600;
    position: relative;
    display: block;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    box-sizing: border-box;
}

.nav > li > span.topheader-span i {
    margin-right: 6px;
}

.tabs-container .nav-tabs > li.active > span,
.tabs-container .nav-tabs > li.active > span:hover,
.tabs-container .nav-tabs > li.active > span:focus {
    border: 1px solid #e7eaec;
    border-bottom-color: transparent;
    background-color: #fff;
}
.tabs-container .nav-tabs > li.active > span,
.tabs-container .nav-tabs > li.active > span:hover,
.tabs-container .nav-tabs > li.active > span:focus {
    border: 1px solid #e7eaec;
    border-bottom-color: transparent;
}
.tabs-container .nav-tabs > li span:hover {
    background: transparent;
    border-color: transparent;
}
.tabs-container .tabs-below > .nav-tabs > li > span {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}
.tabs-container .tabs-below > .nav-tabs > li > span:hover,
.tabs-container .tabs-below > .nav-tabs > li > span:focus {
    border-top-color: #e7eaec;
    border-bottom-color: transparent;
}
.tabs-container .tabs-left > .nav-tabs > li > span,
.tabs-container .tabs-right > .nav-tabs > li > span {
    min-width: 74px;
    margin-right: 0;
    margin-bottom: 3px;
}
.tabs-container .tabs-left > .nav-tabs > li > span {
    margin-right: -1px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}
.tabs-container .tabs-left > .nav-tabs .active > span,
.tabs-container .tabs-left > .nav-tabs .active > span:hover,
.tabs-container .tabs-left > .nav-tabs .active > span:focus {
    border-color: #e7eaec transparent #e7eaec #e7eaec;
}

.tabs-container .tabs-right > .nav-tabs > li > span {
    margin-left: -1px;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}
.tabs-container .tabs-right > .nav-tabs .active > span,
.tabs-container .tabs-right > .nav-tabs .active > span:hover,
.tabs-container .tabs-right > .nav-tabs .active > span:focus {
    border-color: #e7eaec #e7eaec #e7eaec transparent;
    z-index: 1;
}
@media (max-width: 767px) {
    .tabs-container .nav-tabs > li.active > span {
        border-bottom: 1px solid #e7eaec !important;
        margin: 0;
    }
}
.nav-tabs > li > span {
    color: #A7B1C2;
    font-weight: 600;
    padding: 10px 20px 10px 25px;
    display: block;
    cursor: pointer;
}
.nav-tabs > li > span:hover,
.nav-tabs > li > span:focus {
    background-color: #e6e6e6;
    color: #676a6c;
}

.nav-tabs > li > span {
    color: #a7b1c2;
    font-weight: 600;
    padding: 14px 20px 14px 25px;
}

.nav-tabs > li.active > span,
.nav-tabs > li.active > span:hover,
.nav-tabs > li.active > span:focus {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: none;
    border-color: #dddddd #dddddd rgba(0, 0, 0, 0);
    border-bottom: #f3f3f4;
    border-image: none;
    border-style: solid;
    border-width: 1px;
    color: #555555;
    cursor: pointer;
    border-radius: 4px 4px 0px 0px;
}

.tabs-container .tabs-left > .nav-tabs > li > span {
    border-radius: 0px !important;
}

.inherit-max-width-and-max-height {
    max-width: inherit;
    max-height: inherit;
}

.btn-primary[disabled] {
    border-color: transparent !important;
}

.parent {
    position: relative;
}

.child {
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    left: auto !important;
}

.relative-parent {
    position: relative;
}

.relative-child {
    position: absolute;
    bottom: 7px;
    left: 140px;
    width: 18px;
    margin-left:0px;
}

.red-fa-close {
    color: 	#FF0000;
}

.adjust-uploaded-files2 {
    margin-left: 10px !important;
}

.adjust-uploaded-files {
    margin-top: 0  !important;
    margin-right: 0 !important;
}

.multifeed-label-fields-padding {
    padding: 0 !important;
}

.adjust {
    margin-left: 15px !important;
}

.to-front {
    overflow: auto !important;
}

.pointer-events-none,:before,:after {
    pointer-events: none;
}

.collapsed-second-third-level.active {
    background-color: #152630 !important;
}

.widgets-size-preview {
    width: 100%;
    height: 151px;
}

.mobile-asterisk-position:after {
    position: unset !important;
}

.widgets-size-not-preview {
    height: 100%;
}

@media (max-width: 813px) {
    .hidden-s-813 {
        display: none !important;
    }
}

.fc-tippy-tooltip {
    font-size: 1.1rem !important;
    padding: 0.5rem !important;
    color: white;
    white-space: pre-line;
}

.form-top-menu {
    padding: 10px;
    margin-left: -10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-auto {
    cursor: auto;
}

.cursor-default {
    cursor: default;
}

.text-align-center {
    text-align: center;
}

.text-align-end {
    text-align: end;
}

.margin-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.opacity-1 {
    opacity: 1;
}

.opacity-0 {
    opacity: 0.5;
}

.form-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 8000;
}

.ibox-title-label {
    font-size: 16px;
    color: #1976D2;
    line-height: normal;
    font-style: normal;
    font-stretch: normal;
}

.ibox-subtitle-title-label {
    font-size: 10px;
    color: #1976D2;
    line-height: normal;
    font-style: normal;
    font-stretch: normal;
    margin-right:5px;
}

.ibox-subtitle-label {
    font-size: 10px;
    color: #929597;
    line-height: normal;
    font-style: normal;
    font-stretch: normal;
    margin-right:5px;
}

.white-content {
    background-color: white;
    padding-left: 0;
    padding-right: 0;
}


.list-searchbox-row {
    background-color: #e7eaec;
    padding-left: 15px;
}

.no-bullets{
    list-style-type: none;
    padding: 0;
}


@-moz-document url-prefix() {
    .mozilla-display-block {
        display: block !important;
    }
}

.with-fit-content {
    width: fit-content;
}

.float-right {
    float: right;
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}

.word-break-break-all {
    word-break: break-all
}

.legal-urls span{
    margin: 5px;
}

.legal-urls span:hover {
    text-decoration: underline;
}

.gray-border-bottom {
    border-bottom: 1px solid #ccc;
}

.table-link-underline:hover{
    text-decoration: underline;
}

.fa-1x {
    font-size: 1.5em;
    line-height: 2;
}

.h-1em {
    height: 1em;
}

.h-1_2em {
    height: 1.2em;
}

.h-2em {
    height: 2em;
}

.hidden {
    visibility: hidden;
}

.underline {
    text-decoration: underline;
    text-decoration-color: #1976d2;
    text-underline-offset: 10px;
}

.overflow-auto{
    overflow: auto;
}

.overflow-scroll{
    overflow: scroll;
}
/* Use this instead of the other class .hidden because of conflict with the bootstrap css class with the same name.*/
.hidden-visibility {
    visibility: hidden;
}

/** Padding **/
.py-10{
    padding: 10px 0;
}

.py-20{
    padding: 20px 0;
}

.py-40{
    padding: 40px 0;
}

.px-10 {
    padding: 0 10px;
}

.px-20 {
    padding: 0 20px;
}

.px-40{
    padding: 0 40px;
}