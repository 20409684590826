$base-color: #337ab7;

.deployed-status-text {
  font-size: 11px;
  text-align: left;
  color: #676a6c;
}

.rule-form{
  background-color: white;
  z-index: 4;
  margin-bottom: 4rem;
  padding: 20px;
  position: relative;
}

.rule-form .tabs-container .nav-tabs > li.active > span{
  border: none;
}

.rule-form .tabs-container .nav-tabs > li.active > span{
  border: none;
}

.rule-top-menu {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.rule-form-mobile-top-header {
  background-color: #FFFF !important;
}

.rule-form > .tabs-container > .tabs-top > .nav-tabs {
  border: none
}

.rules-deployment-btn {
  background: white;
  border-radius: 20px;
  min-width: 80px;
  border: solid 1px #676a6c;
  color: #676a6c;
  margin-right: 15px;
  font-size: 13px;
  height: 40px;

  &:hover {
    cursor: pointer;
    background: rgba(103, 106, 108, 0.02);
    border: solid 1px #676a6c;
    color: #676a6c;
  }

  &.disabled {
    cursor: not-allowed;
    color: inherit;
    border: 1px solid #d2d2d2;
    background-color: #e6e6e6;
  }

  &.disabled:hover {
    cursor: not-allowed;
  }

}

.active.rule-tab-header{
  border: none !important;
  border-bottom: 3px solid #84C13E !important;
}

.active.rule-tab-header > span {
  background-color: inherit !important;
  border: none !important;
}

.rule-tab-header  > a {
  margin-right: 0 !important;
}

.rule-icon-image {
  object-fit: contain;
  cursor: pointer;
  width: 23px;
}