.action {
  border: none;
  background-color: #f7f7f7;
  box-shadow: 0 0 10px 0 hsla(0,0%,60.8%,.3);

  .action-heading {
    border: none;
    background-color: #f7f7f7;

    .action-title {
      margin: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .action-title-btn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .action-body {
    background-color: #f7f7f7;

    .action-inside-helper{
      display: flex;
    }
  }

}


.arrow-direction {
  transform: rotate(-90deg);
  transition-duration: 500ms;

  &.up {
    transform: rotate(90deg);
    transition-duration: 500ms;
  }

}


