.universal-search {
    float: right;
    min-width: 240px;
}

.universal-search-icon {
    pointer-events: all !important;
}

div.two-input {
    padding: 0;
    display: inline-flex;
    text-align: left;
    width: 100%;
}

.min-width-dialcode {
    min-width: 130px;
}

.border-radius-dialcode > div.Select-control {
    border-radius: 0px;
}

div.two-input > div.Select.is-open {
    z-index: 10;
}

div.Select.two-input-drop-firstItem {
    padding: 0;
}

.labeled-field-dropdown > .Select-control > .Select-multi-value-wrapper {
    display: table-cell;
    margin-right: 10px;
    overflow: hidden;
}

.labeled-field-dropdown > .Select-control > .Select-multi-value-wrapper > .Select-input > .Select-input {
    margin-left: 0;
}

div.Select.two-input-drop-firstItem > .Select-control {
    border-bottom-color: rgb(229, 230, 231);
    border-bottom-style:  solid;
    border-bottom-width:  1px;
    border-collapse:  separate;
    border-image-outset:  0px;
    border-image-repeat:  stretch;
    border-image-slice:  100%;
    border-image-source:  none;
    border-image-width:  1;
    border-left-color:  rgb(229, 230, 231);
    border-left-style:  solid;
    border-left-width:  1px;
    border-right-color:  rgb(229, 230, 231);
    border-right-style:  solid;
    border-right-width: 1px;
    border-top-color:  rgb(229, 230, 231);
    border-radius: 0 1px 1px 0;
    border-top-style:  solid;
    border-top-width:  1px;
}

div.Select.two-input-drop-secondItem {
    border-radius: 0;
    height: 36px;
}

.two-input-drop-secondItem {
    border-radius: 0;
    height: 36px;
}

div.Select.two-input-drop:focus {
    background-color: yellow;
}

.two-input-drop-secondItem > .Select-control > .Select-multi-value-wrapper > .Select-value > .Select-value-label {
    vertical-align: middle;
    display: initial;
    word-break: break-all;
}

.clear-datetime-button {
    cursor: hand;
    padding-right: 25px;
    font-size: 18px;
    float: right;
    z-index: 3;
    position: absolute;
    right: 15px;
}

.clear-datetime-button-force-right {
    position: absolute;
}

.datetime-parent-container {
    width: 100%;
    background-color: white;
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    height: 36px;
    display: flex;
}

.datetime-container {
    display: inline-flex;
    width: 92%;
}

.datetime-container-disabled {
    display: inline-flex;
    width: 100%;
}

.datetime-close-container {
    height: 100%;
    padding-top: 4px;
    display: inline-flex;
    width: 8%;
}

.datetime-container > .rdt, .datetime-container > .rdt > .form-control {
    background-color: transparent;
    border: none;
}

.datetime-container-disabled > .rdt {
    width: 100%;
}

.datetime-container > .rdt {
    width: 100%;
}

.datetime-container > .rdt > .rdtPicker > .rdtTime > table,
.datetime-container > .rdt > .rdtPicker > .rdtDays > table {
    table-layout: auto;
}

/*loading icon*/
.labeled-field-loading {
    margin-left: 20px;
}

.labeled-field-loading > i {
    font-size: small !important;
}

.dropdown-loading-arrow .Select-arrow-zone {
    opacity: 1 !important;
}
