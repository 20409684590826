.interface-status-active {
    color: #19aa8d;
}
.interface-status-closed {
    color: #676a6c;
}
.interface-status-expired {
    color: #ed5565;
}
.interface-status-disabled {
    color: #f8ac59;
}

.interface-form{
    padding: 20px 20px 20px 20px;
    background-color: #edeeef;
}

.interface-form .row-buffer{
    margin-top:20px
}

.interface-form .row-buffer-plus{
    margin-top:40px
}

.interface-status-active {
    color: #19aa8d;
}

.interface-status-inactive {
    color: #ed5565;
}

.interface-form{
    padding: 20px;
    background-color: #edeeef;
    position: relative;
    z-index: 4;
}