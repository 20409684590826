.set-password-modal {
    outline: none;
    border-radius: 5px;
    background-color: #FFFFFF;
    -ms-transform: translate(-50%, -50%);
    display: block;
    top: 10%;
    width: 30%;
    left: 35%;
    position: absolute;
}

.set-password-modal-content h1{
    font-size: 50px;
}

.set-password-modal-content h3{
    font-size: 20px;
}

.set-password-modal-content p{
    font-size: 15px;
}

.set-password-fields{
    margin: 40px 50px;
}