.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.loading > i {
    font-size: 50px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: #1976D2;
}
