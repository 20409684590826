@import "src/styles/base/global";

$base-color: #1976D2;

.tr-icon-color {
  color: $base-color;

  &:hover{
    color: #1564C0
  }
}

@media (min-width: 200px) and (max-width: 992px) {
  .pagination-panel {
    flex-direction: column-reverse;
  }
}

.pagination-panel {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  background-color: rgba(216, 216, 216, .12);
  padding: 10px 20px;
  margin: 10px -5px;
  align-items: center;

  .pagination-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: stretch;

    .react-bootstrap-table-pagination-total {

      display: flex;
      align-items: center;

      .size-per-page {
        margin-right: 5px;
        .btn-group {
          button {
            font-size: 16px;
            color: $base-color;
            font-weight: bolder;
            border: none;
            background-color: transparent;
            padding: 0;
            margin-bottom: 0;

            &:focus {
              outline: none;
              background-color: transparent;
            }

            &:hover{
              background-color: transparent;
            }

          }
        }

        ul {
          min-width: 50px;
        }
      }
    }

    .pagination {
      background-color: transparent;
      border: none;
      margin: 0;

      &.react-bootstrap-table-page-btns-ul {
        li:nth-child(7),
        li:nth-child(1) {
          &:not(.disabled) {
            > a {
              color: $base-color;
              font-weight: bolder;
            }
          }
        }
      }

      a:hover {
        background-color: transparent;
        font-weight: bolder;
      }

      .page-item {
        border: none;

        &:not(.disabled){
          a {
            background-color: transparent;
          }
        }

      }

      li {
        a {
          background-color: transparent;
          border: none;
          color: inherit;
          z-index: 2;
          padding: 4px;
        }
      }

      .active {
        a {
          background-color: transparent;
          border: none;
          color: $base-color;
          font-weight: bolder;
          z-index: 2;
        }
      }

    }
  }

}


.pagination > li:not(.active) > {
  a:not(:hover):focus, span:not(:hover):focus {
    background-color: transparent !important;
    color: $base-color !important;
  }
}

.fc-table {
  tr {
    :focus {
      outline: none;
    }

    :hover {
      cursor: pointer;
    }
    height: 53px; //tr min-height

    td {

      &.table-column-fix-height {
        height: 53px;
        padding-top: 5px;
        padding-bottom: 5px;

        .table-column-fix-td-div {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          word-break: break-word;
        }

      }

      a {
        color: $font-gray;

        &:hover {
          text-decoration: underline;
        }

        &.no-underline {
          text-decoration: none;
        }

        :not(&.no-underline) {
          > span {
            min-height: 53px;
          }

        }

      }

      .multi-line {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
      }

    }

  }

  .col-span {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .col-span-align-right{
    min-width: 80px;
    text-align: right;
  }

  .col-header {
    border-bottom: 1px solid #e7eaec;
  }

  &.mobile {
    overflow-x: auto;
  }

}
