@media (max-width:950px) {
    #sidePanel{
        visibility: hidden;
    }

    .scroll-up{
        display: none;
        visibility: hidden;
    }
}

.theme-config {
    top: 250px;
    z-index: 1;
}

.spin-icon {
    padding: 6px 10px 7px 10px;
    font-size: 28px;
    width: 67px;
}

.title-Configuration {
    height: 53px;
    background: #1ab394;
}

.title-Configuration > h3 {
    color: white;
    padding-bottom: 20px;
    padding-top: 20px;
}

.sidepanel-homepage-icon {
    float: right;
    padding-right: 14px;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    cursor: default;
    margin-left: 6px;
}

.skin-settings {
    width: 187px;
    margin-left: 67px;
    -webkit-transition: width 0.3s ease-in-out;
    -moz-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
}
.skin-settings.skin-settings-over {
    width: 210px;
    margin-left: 67px;
}

.skin-settings.show {
    width: 650px;
    margin-left: 67px;
}

.sidePanel{
    background-color: white;
    height: 570px;
    overflow: auto;
    padding: 20px 20px 20px 20px;
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
}