.img-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-left: 20px;

  &.back {
    margin-left: 0;
  }

  img {
    cursor: pointer;

    &.disabled {
      cursor: unset;
    }
  }

}

.img-btn-inline {
  display: inline-block;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-left: 0px;
  &.back {
    margin-left: 0;
  }

  img {
    cursor: pointer;

    &.disabled {
      cursor: unset;
    }
  }

}