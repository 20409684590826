@import "./src/styles/base/global";

.base-panel {
  padding: 20px;
  margin: 30px;
  position: relative;
  z-index: 4;
  box-shadow: 0 0 8px 0 rgba(188, 188, 188, 0.6);
  background-color: #ffffff;

  &.mobile {
    margin: 0;
  }

  &.full-height {
    min-height: calc(100vh - (#{$navigation-header-height} + 60px));
  }

}

.base-panel .searchbox{
  margin-left: -20px;
  margin-right: -20px;
}

.base-panel-row {
  margin: 0;
}

.base-panel-inner {
  margin: 0;
  min-height: fit-content;
}

