
//navigation width
$left-panel-width: 250px;

$left-mini-panel-width: 25px;

//dashboard navigation width
$dashboard-left-panel-width: 400px;

//header panel height
$header-height: 88px;

//header panel height
$header-height-mobile: 60px;

//footer
$footer-height: 88px;

//
$navigation-header-height: 88px;


//colors
$dark-blue: #152630;
$navigation-background: #1e303a;
//$btn-color: #337ab7;
//$font-color: #4a90e2;
//$secondary-color: #93c01f;
$font-gray: #878787;
$background-gray: #f3f3f4;




