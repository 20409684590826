.entity-status-active {
    color: #19aa8d;
}
.entity-status-closed {
    color: #676a6c;
}
.entity-status-expired {
    color: #ed5565;
}
.entity-status-disabled {
    color: #f8ac59;
}

.entity-form{
    padding: 20px 20px 20px 20px;
    background-color: #edeeef;
    position: relative;
    z-index: 4;
}

.entity-form .row-buffer{
    margin-top:20px
}

.entity-form .row-buffer-plus{
    margin-top:40px
}

.entity-div-field-error{
    align-self: flex-end;
    padding-top: 15px;
    width: 100%;
}

.tab-anchor-no-hover :hover{
    color: #a7b1c2 !important;
    cursor: default !important;
}

.has-error-color{
    color:#a94442;
}

div.modal-map-container {
    padding-top: 0 !important;
    padding-bottom: 53px !important;
    background-color: #edeeef;
}

.entities-map > .col-md-12 {
    border-radius: 10px;
    background-color: white;
    height: 450px;
    border-top-width: 2px;
    margin-top: 0px;
    padding: 10px;
}

img.popUp-close-bot {
    cursor: pointer;
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 100;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 30px;
}

@media (max-width: 813px) {
    img.popUp-close-bot {
        right: 0 !important;
    }
}

div.draw-on-map {
    padding-top: 5px;
}

.transfer-modal-icon {
    font-size: 50px;
    color: #2C7AB9;
}

.transfer-modal-title{
    font-size: 30px;
}

.transfer-modal-helper{
    font-size: 15px;
}

.transfer-modal-content{
    margin: 20px 25px;
}

.upload-image-entity-tab,
.upload-placeholder-entity-tab {
    background-color: white;
    border-color: white;
    color: white;
}

.upload-placeholder-entity-tab:hover {
    background-color: #2f4050;
    border-color: #2f4050;
    color: #2f4050;
}

.export-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: stretch;
}

.export-actions button {
    background-color: transparent !important;
    border: none  !important;
    margin: 0 !important;
    box-shadow: none !important;
    padding: 0 !important;
}

.qrcode {
    padding: 20px;
}

.qrcode-inner-container{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 510px;
    border: 1px solid #000000;
}

.qrcode-scan-message{
    margin-top: 10px;
    font-weight: bold;
    text-align: center;
}

.qrcode h2 {
    font-weight: bold;
}

.qrcode h4 {
    margin-bottom: 20px;
}

.generate-qrcode-button {
    box-shadow: rgb(174 161 161) 0 8px 15px;
    font-weight: bold;
}

.generate-qrcode-button:hover{
    transform: scale(0.97);
    transition: transform 0.2s ease-in;
}

.qrcode-button {
    background: #337ab7;
    color: #fff;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    transition: background-color 0.2s ease-in;
}

.qrcode-button:hover {
    color: #fff;
    background: #1b598a;
    transform: scale(0.98);
}

.download-qrcode-modal {
    width: 30% !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
}

.qrcode-img {
    margin-top: 10px;
    max-width: 120px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}