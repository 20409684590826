$base-color: #337ab7;

.event-list-searchbox-row {
  padding-left: 15px;
  padding-right: 15px;
}
.event-status-closed {
  color: #676a6c;
}
.event-status-open {
  color: $base-color;
}
.event-status-pending {
  color: #f8ac59;
}
.event-severity-low {
  color: #B6D0D3;
}
.event-severity-moderate {
  color: #E6D18B;
}
.event-severity-major {
  color: #F7A262;
}
.event-severity-critical {
  color: #E86733;
}

.events-submit-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.close-events-btn{
  background: white !important;
  border-radius: 20px;
  min-width: 150px;
  border: solid 1px #676a6c !important;
  color: #676a6c !important;

  &:focus {
    background: white;
    border-radius: 20px;
  }

  &:active {
    background: white;
    border-radius: 20px;
  }

  &:hover {
    cursor: auto;
    background: white;
    border: solid 1px #676a6c;
    color: #676a6c;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
  }

  &.active {
    background: $base-color !important;
    color: white !important;
    border: none !important;
    box-shadow: none;

    &:hover {
      cursor: pointer;
      background: $base-color;
      border: none;
      color: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
    }

    &:focus {
      background: $base-color;
      color: white;
      border: none;
    }

    &:active {
      background: $base-color;
      color: white;
      border: none;
    }

  }

}

.has-parent {
  padding: 0;
  margin: 0;
  box-shadow: none;
}

.event-notification-counter-container{
  border-radius: 5px;
  width: 20px;
  color: white;
  justify-content: center;
  display: flex;
  margin-right: -30px;
  z-index: 1;
  margin-bottom: 16px;
}


