@import "src/styles/base/global";

.error-page {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  left: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  z-index: 2;
  background-color: $background-gray;


  &.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100%;

    img  {
      width: 300px;
      margin-bottom: 40px;
      object-fit: contain;
    }

  }

}