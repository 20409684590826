.interface-readings-modal {
    width: 80%!important;
    height: 80%!important;
    transform: translate(-50%, -50%);
    top: 50%!important;
    left: 50%!important;
    position: relative;
    margin-top: 20px;
    z-index: 4;
    box-shadow: 0 0 8px 0 rgba(188, 188, 188, 0.6);
    background-color: #ffffff;

    .base-panel {
        box-shadow: none;
        padding: 10px;

        .chip-container {
            display: flex;
            min-width: 200px;
            flex-direction: row;
        }
    }
    .modal-content {
        .popUp-close-bot{
            right: -10px!important;
        }
    }
}

.readings-chart-content-container {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
}

.readings-table-content-container {
    border-left: 2px solid #f0f0f0;
    padding: 0;
    overflow: hidden;

    .fc-table {
        tr {
            height: 40px !important;
        }
    }

}

.terminal-interfaces {
    margin: 0;

    .interfaces-table-title-container {
        margin-bottom: 20px;
        margin-right: -20px;
        margin-left: -20px;

        .table-title-label {
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
        }
    }

}


