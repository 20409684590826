.main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    img {
        width: 30%;
        -webkit-animation: flickerAnimation 10s infinite;
        -moz-animation: flickerAnimation 10s infinite;
        -o-animation: flickerAnimation 10s infinite;
        animation: flickerAnimation 10s infinite;
    }
}

@keyframes flickerAnimation {
    0%   { opacity:0.2; }
    50%  { opacity:0.5; }
    100% { opacity:0.2; }
}
@-o-keyframes flickerAnimation{
    0%   { opacity:0.2; }
    50%  { opacity:0.5; }
    100% { opacity:0.2; }
}
@-moz-keyframes flickerAnimation{
    0%   { opacity:0.2; }
    50%  { opacity:0.5; }
    100% { opacity:0.2; }
}
@-webkit-keyframes flickerAnimation{
    0%   { opacity:0.2; }
    50%  { opacity:0.5; }
    100% { opacity:0.2; }
}

