div.map-area {
    z-index: 100;
    position: absolute;
    margin-left: 10px;
    margin-top: 90px;
    width: 250px;
    height: 32px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
    font-size: 14px;
    padding: 6px 12px;
    background-color: white;
}

div.map-info {
    z-index: 100;
    position: absolute;
    margin-left: 10px;
    margin-top: 132px;
    background-color: rgb(167, 169, 172);
    color: white;
    width: 215px;
    height: 45px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
    font-size: 12px;
    padding: 6px 12px;
}

div.map-info .hidden {
    visibility: hidden;
}

div.map-info-trash {
    z-index: 100;
    position: absolute;
    margin-left: 135px;
    margin-top: 132px;
    background-color: rgb(167, 169, 172);
    color: white;
    width: 125px;
    height: 30px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
    font-size: 12px;
    padding: 6px 12px;
}

div.map-info-trash .hidden {
    visibility: hidden;
}

div.map-area > .icon {
    height: 20px;
    width: 20px;
    position: inherit;
    left: 197px;
}

div.map-area > .trash {
    position: inherit;
    left: 225px;
    font-size: 20px;
    vertical-align: top;
    color: rgb(167, 169, 172);
}

.entity-name {
    font-size: 22px;
    color: #4889BF;
    cursor: pointer;
    max-width: 300px;
    overflow-x: hidden;
    margin: 10px 0;
}

.entity-events {
    overflow-y: auto;
    overflow-x: hidden;
    overflow-wrap: break-word;
}

button.map-modal-button {
    z-index: 100;
    margin-top: 20px;
    margin-right: 5px;
    margin-left: 5px;
    float: right;
}

.gm-style-iw{
    width: 350px !important;
}
