.created-update-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  width: fit-content;
  border-bottom: 2px solid #9e9e9e;

  p {
    margin-bottom: 0;
  }

  .created {
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .created-update-wrapper {
    display: none;
  }
}
