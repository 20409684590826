.terminal-text-color {
    color: #1564C0;
}

.terminal-status-active {
    color: #19aa8d;
}

.terminal-status-inactive {
    color: #ed5565;
}

.terminal-discovery-active {
    color: #19aa8d;
}

.terminal-discovery-inactive {
    color: #ed5565;
}


.terminal-details-panel-element {
    padding-top: 15px;
    padding-bottom: 15px;

    label {
        font-size: 15px;
    }

    .text-field-info {
        padding: 0;
    }
}

.terminal-info-details-title {
    background-color: #e9ecf0;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -20px;
    margin-right: -20px;
}

.terminal-info-details-button {
    display: flex;
    float: right;
}

.terminal-info-details-container {
    display: flex;
    background-color: #e9ecf0;
    margin-left: -20px;
    margin-right: -20px;
}

.actions-button {
    display: inline-block;

    .dz-default {
        font-size: 12px;
    }

    button {
        border-radius: 25px;
        border: 2px solid;
    }
}

/**/

.col-hidden {
    display: none;
}

.table-title-container {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    color: inherit;
    margin-bottom: 0;
    padding: 15px 20px 15px;
    min-height: 48px;
    border-bottom: solid 1px #b1c7da;
}

.table-title-label {
    font-size: 16px;
    color: #1564C0;
}

.details-table-container {
    border-radius: 9px;
    box-shadow: 0 0 8px 0 rgba(188, 188, 188, 0.6);
    background-color: #ffffff;
}

.details-table-container-height {
    min-height: 300px;
    height: 300px;
}

.table-container {
    box-shadow: 0 0 8px 0 rgba(188, 188, 188, 0.6);
    background-color: #ffffff;
}

.table-container-inside-other-container {
    border-radius: 9px;
    box-shadow: 0 0 8px 0 rgba(188, 188, 188, 0.6);
    background-color: #ffffff;
    padding-bottom: 20px;
}