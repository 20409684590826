.error-section {
  border: 2px solid #f44336;
  border-radius: 5px;
  padding: 15px;
  margin: 20px 0;
  background-color: #ffebee;
}

.error-list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.error-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: #ffcdd2;
  padding: 10px;
  border-radius: 5px;
}

.error-icon {
  color: #f44336;
  font-size: 20px;
  margin-right: 10px;
}

.error-message {
  color: #d32f2f;
}