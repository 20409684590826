.smooth-dnd-container.horizontal {
    height:100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
}
.smooth-dnd-container *{
    min-width: 25%;
}
.sc-dnqmqq.dRwmhN.sc-htoDjs.jHtXmx{
    max-width: 100%;
}
.sc-EHOje.bsakmj,
.sc-EHOje.ejtCpj {
    /* autoprefixer: off */
    width: -webkit-fill-available;
}

.board-loading {
    position: absolute;
    top: 97%;
    left: 180px;
}

.board-loading  i {
    font-size: 30px !important;
}

.board-loading .loading {
    width: 30px !important;
}