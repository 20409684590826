@font-face {
    font-family: 'icomoon';
    src:  url('./../fonts/icomoon.eot?27g31j');
    src:  url('./../fonts/icomoon.eot?27g31j#iefix') format('embedded-opentype'),
    url('./../fonts/icomoon.ttf?27g31j') format('truetype'),
    url('./../fonts/icomoon.woff?27g31j') format('woff'),
    url('./../fonts/icomoon.svg?27g31j#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="fc-icon-"], [class*=" fc-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fc-icon-checklist:before {
    content: "\e900";
    size: 14px;
}
.fc-icon-readings:before {
    content: "\e901";
    size: 14px;
}
.fc-icon-sector:before {
    content: "\e902";
    size: 14px;
}
.fc-icon-terminal:before {
    content: "\e903";
    size: 14px;
}
.fc-icon-undeploy_rule:before {
    content: "\e904";
    size: 14px;
}