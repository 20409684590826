.react-trello-board > div {
    min-width: 1600px;
}

.list-view .search-panel-heading .search-panel-title {
    justify-content: flex-end;
}

.react-trello-lane {
    background-color: #F3F3F4 !important;
    border-radius: 10px !important;
}

.smooth-dnd-container {
    min-height: 500px !important;
    margin-right: 1px;
}

.smooth-dnd-container * {
    min-width: 20% !important;
}

.card-wrapper {
    margin-bottom: 20px;
    padding: 0;
    border-radius: 0;
    border-bottom: 0;
}

.card {
    background-color: #fffefe;
    height: 160px;
    width: 360px;
    margin-bottom: 10px;
    cursor: pointer;
}

.card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: stretch;
    height: 100%;
}

.card-content-child {
    align-self: stretch;
    padding: 15px;
}

.card-content-child-title {
    display: flex;
    justify-content: space-between;
}

.card-second-level > .row {
    margin-left: 0;
    margin-right: 0;
}

.priority-icon {
    position: absolute;
    right: 0;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    min-width: 20px !important;
}

.priority-critical {
    color: #f56a7b;
}
.priority-high {
    color: #FF9E14;
}
.priority-normal {
    color: #83b0e5;
}

.priority-icon-critical {
    border-top: 20px solid #f56a7b;
}

.priority-icon-high {
    border-top: 20px solid #FF9E14;
}

.priority-icon-normal {
    border-top: 20px solid #83b0e5;
}

.card-priority {
    margin: 0;
    text-align: end;
}

.card-priority p {
    margin: 0;
}

.card-priority-label {
    color: #9b9b9b;
    font-size: 8px;
    font-weight: 300;
}

.card-priority-title {
    font-size: 10px;
    font-weight: 600;
    color: #4a4a4a;
}

.card-title p {
    font-size: 15px;
    font-weight: 600;
    color: #4a4a4a;
    word-break: break-all;
    margin: 0;
    white-space: normal;
}

.card-category {
    border-radius: 360px;
    border: solid 1px #83b0e5;
    color: #83b0e5;
    margin: 0 10px 10px 10px;
    text-align: center;
    width: fit-content;
    padding: 3px 6px;
}

.card-second-level {
    background-color: #f8f8f8;
    max-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 4px 0;
}

.card-entity {
    margin-left: 15px;
    color: #9b9b9b;
    font-size: 13px;
}

.card-second-level p {
    margin: 0;
}

.card-users {
    color: #9b9b9b;
    margin-left: 15px;
}

.cart-due-date {
    font-size: 12px;
    text-align: end;
    margin-right: 15px;
    color: #9b9b9b;
}

.lane-header {
    font-size: 14px;
    font-weight: 600;
    color: #9b9b9b;
    display: flex;
    justify-content: space-between;
}

.lane-header p {
    min-width: fit-content !important;
}

.lane-header-hr {
    border: 1px solid #eaeaea;
}

.react_trello_dragClass {
    transform: rotate(0deg) !important;
}


/*scrollbar*/
.smooth-dnd-container *::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.react-trello-board::-webkit-scrollbar-track,
.smooth-dnd-container *::-webkit-scrollbar-track {
    background: #d8d8d8;
    border-radius: 20px;
    opacity: 0.6;
}

/* Handle */
.react-trello-board::-webkit-scrollbar-thumb,
.smooth-dnd-container *::-webkit-scrollbar-thumb {
    background: #a4a4a4;
    border-radius: 20px;
    opacity: 0.7;
}

/* Handle on hover */
.react-trello-board::-webkit-scrollbar-thumb:hover,
.smooth-dnd-container *::-webkit-scrollbar-thumb:hover {
    background: #a4a4a4;
}

.react-trello-board::-webkit-scrollbar,
.smooth-dnd-container *::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
