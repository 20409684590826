.subscription-template-form{
    background-color: white;
    z-index: 4;
    margin-bottom: 4rem;
    padding: 20px;
    position: relative;
}

.report-generic-fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
}

.report-silo-line {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
}

.report-line-delete-icon {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
}

.recurrence-header {
    padding-left: 15px;
    font-size: 16px;
}