@media (min-width: 1550px) {
    .col-xlg-1, .col-xlg-2, .col-xlg-3, .col-xlg-4, .col-xlg-5, .col-xlg-6, .col-xlg-7, .col-xlg-8, .col-xlg-9, .col-xlg-10, .col-xlg-11, .col-xlg-12 {
        float: left;
    }
    .col-xlg-12 {
        width: 100%;
    }
    .col-xlg-11 {
        width: 91.66666667%;
    }
    .col-xlg-10 {
        width: 83.33333333%;
    }
    .col-xlg-9 {
        width: 75%;
    }
    .col-xlg-8 {
        width: 66.66666667%;
    }
    .col-xlg-7 {
        width: 58.33333333%;
    }
    .col-xlg-6 {
        width: 50%;
    }
    .col-xlg-5 {
        width: 41.66666667%;
    }
    .col-xlg-4 {
        width: 33.33333333%;
    }
    .col-xlg-3 {
        width: 25%;
    }
    .col-xlg-2 {
        width: 16.66666667%;
    }
    .col-xlg-1 {
        width: 8.33333333%;
    }
    .col-xlg-pull-12 {
        right: 100%;
    }
    .col-xlg-pull-11 {
        right: 91.66666667%;
    }
    .col-xlg-pull-10 {
        right: 83.33333333%;
    }
    .col-xlg-pull-9 {
        right: 75%;
    }
    .col-xlg-pull-8 {
        right: 66.66666667%;
    }
    .col-xlg-pull-7 {
        right: 58.33333333%;
    }
    .col-xlg-pull-6 {
        right: 50%;
    }
    .col-xlg-pull-5 {
        right: 41.66666667%;
    }
    .col-xlg-pull-4 {
        right: 33.33333333%;
    }
    .col-xlg-pull-3 {
        right: 25%;
    }
    .col-xlg-pull-2 {
        right: 16.66666667%;
    }
    .col-xlg-pull-1 {
        right: 8.33333333%;
    }
    .col-xlg-pull-0 {
        right: auto;
    }
    .col-xlg-push-12 {
        left: 100%;
    }
    .col-xlg-push-11 {
        left: 91.66666667%;
    }
    .col-xlg-push-10 {
        left: 83.33333333%;
    }
    .col-xlg-push-9 {
        left: 75%;
    }
    .col-xlg-push-8 {
        left: 66.66666667%;
    }
    .col-xlg-push-7 {
        left: 58.33333333%;
    }
    .col-xlg-push-6 {
        left: 50%;
    }
    .col-xlg-push-5 {
        left: 41.66666667%;
    }
    .col-xlg-push-4 {
        left: 33.33333333%;
    }
    .col-xlg-push-3 {
        left: 25%;
    }
    .col-xlg-push-2 {
        left: 16.66666667%;
    }
    .col-xlg-push-1 {
        left: 8.33333333%;
    }
    .col-xlg-push-0 {
        left: auto;
    }
    .col-xlg-offset-12 {
        margin-left: 100%;
    }
    .col-xlg-offset-11 {
        margin-left: 91.66666667%;
    }
    .col-xlg-offset-10 {
        margin-left: 83.33333333%;
    }
    .col-xlg-offset-9 {
        margin-left: 75%;
    }
    .col-xlg-offset-8 {
        margin-left: 66.66666667%;
    }
    .col-xlg-offset-7 {
        margin-left: 58.33333333%;
    }
    .col-xlg-offset-6 {
        margin-left: 50%;
    }
    .col-xlg-offset-5 {
        margin-left: 41.66666667%;
    }
    .col-xlg-offset-4 {
        margin-left: 33.33333333%;
    }
    .col-xlg-offset-3 {
        margin-left: 25%;
    }
    .col-xlg-offset-2 {
        margin-left: 16.66666667%;
    }
    .col-xlg-offset-1 {
        margin-left: 8.33333333%;
    }
    .col-xlg-offset-0 {
        margin-left: 0;
    }
}