.multi-create-dropdown-button {
  height: 40px;
  label {
    margin-right: 0 !important;
    margin-bottom:  0 !important;
    font-size: 0;
  }
}

.has-parent {
  padding: 0;
  margin: 0;
  box-shadow: none;
}
