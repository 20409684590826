.widget {
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    h2 {
        margin-top: 5px;
        margin-bottom: 0;
    }
    h3 {
        margin-top: 5px;
        margin-bottom: 0;
    }
    .flot-chart {
        height: 100px;
    }
}

.widget-chooser {
    height: 100%;
    overflow: hidden;

    h2 {
        margin-top: 5px;
        margin-bottom: 0;
    }
    h3 {
        margin-top: 5px;
        margin-bottom: 0;
    }
    .flot-chart {
        height: 100px;
    }
}

.widget-direct-value {
    padding: 15px 20px;
}
.widget.style1 {
    h2 {
        font-size: 30px;
    }
}
.widget-text-box {
    padding: 20px;
    border: 1px solid #e7eaec;
    background: #ffffff;
}
.widget-head-color-box {
    border-radius: 5px 5px 0 0;
    margin-top: 10px;
}
.chart-options {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    display: flex;
    background-color: white;
    transition: display 2s linear;
}
.widget-example-highlight {
    -webkit-box-shadow: #B3B3B3 5px 5px 5px;
    -moz-box-shadow: #B3B3B3 5px 5px 5px;
    box-shadow: #B3B3B3 5px 5px 5px;
    cursor: pointer;
    height: 150px;
    margin-bottom: 20px;
}
.chart-panel-border {
    border: 2px solid #e7eaec;
}
.delete-icon-top-right {
    position: absolute;
    top: 5px;
    right: 5px;
    color: inherit;
}
.controller-container {
    display: flex;
    height: 100% !important;
}
.controller-valign-center {
    padding-top: 10px;
}
.controller-vertical-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.silo-vertical-container {
    display: flex;
    flex-direction: column;
}
.controller-element {
    position: relative;
    align-self: stretch;
    text-align: center;
}
.controller-nav {
    width: 200px;
}
.controller-info-area {
    background-color: #ffffff;
    position: relative;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: auto;
}
.controller-text-align {
    align-self: flex-start;
    padding-left: 15px;
}
.controller-element-3 {
    flex: 3;
}
.controller-element-2 {
    flex: 2;
}
.controller-element-1 {
    flex: 1;
}
.controller-secondary-area {
    display: flex;
    flex-direction: column;
    background-color: white;
}
.controller-input-fg {
    color: #6F7172;
}
.controller-input-bg {
    background-color: #eaeaea;
}
.controller-input-switch-active-bg {
    color: #ffffff;
    background-color: #7ACBBD;
}
.controller-input-switch-inactive-bg {
    color: #ffffff;
    background-color: #b3b3b3;
}
.controller-info-bg {
    background-color: #e3a569;
}
.controller-info-switch-active-bg {
    background-color: #12b495;
}
.controller-info-switch-inactive-bg {
    background-color: #bdbec0;
}
.controller-edit {
    background-color: #929092;
    color: white;
    cursor: pointer;
    max-height: 30px;
    width: 100%;
    border-bottom-left-radius: 5px;
}
.controller-edit-cancel {
    background-color: #a3a2a3;
    color: white;
    cursor: pointer;
    border-bottom-left-radius: 5px;
}
.controller-edit-confirm {
    background-color: #929092;
    cursor: pointer;
}
.controller-switch-active-bg {
    background-color: #1d9d7d;
}
.controller-switch-inactive-bg {
    background-color: #939598;
}
.controller-value-area {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-width: 120px;
}
.controller-value-background {
    background-color: #F0EEEE;
}
.controller-temporary-icon {
    position: fixed;
    left: 5px;
    top: 5px;
}
.display-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
h2.value-display {
    font-weight: 800;
}
.widget-options-contained {
    align-self: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
}
.widget-options-effect {
    color: #dfdfdf;
    transition: color 0.5s ease;
    &:hover {
        color: #676a6c;
    }
}
.widget-options {
    float: right;
    display: flex;
}
.widget-top-corners {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.widget-bottom-corners {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
    input {
        display: none;
    }
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
        position: absolute;
        content: "";
        height: 25px;
        width: 25px;
        left: -2px;
        bottom: 0;
        background-color: #929092;
        -webkit-transition: .4s;
        transition: .4s;
    }
}
input {
    &:checked {
        + {
            .slider {
                background-color: #ffffff;
                &:before {
                    -webkit-transform: translateX(24px);
                    -ms-transform: translateX(24px);
                    transform: translateX(24px);
                }
            }
        }
    }
    &:focus {
        + {
            .slider {
                box-shadow: 0 0 1px #ccc;
            }
        }
    }
}
.slider.round {
    border-radius: 17px;
    &:before {
        border-radius: 50%;
    }
}
.ibox-content-no-border {
    padding: 0px 0;
}
.ibox-content-center-items {
    text-align: center;
}
.stickyNote-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
.wordBreak {
    word-break: break-word;
}

.ellipsis {
    text-overflow: ellipsis;
}

.widget-configs-area {
    overflow-y: visible;
}
.widget-image-example {
    object-fit: contain;
    cursor: pointer;
    width: 75%;
    height: 75%;
}
.widget-multiple-interface-example {
    width: 100%;
    cursor: pointer;
    height: 150px;
}
.widget-multiple-interface {
    .dragdrop-context {
        flex-wrap: nowrap;
    }
}
.multi-interface-widget.table {
    margin-bottom: 0;
}
.widget-image {
    height: 100%;
    align-self: center;
    width: 100%;
    object-fit: fill;
}
.no-padding {
    padding: 0;
}
.widget-title {
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.simpleColorPicker {
    >div {
        &:nth-child(2) {
            >div.twitter-picker {
                >div {
                    &:nth-child(3) {
                        >span {
                            &:nth-child(1) {
                                >div {
                                    border: 2px solid #F9F9F9;
                                }
                            }
                        }
                        >div {
                            &:nth-child(3) {
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }
    input {
        display: none !important;
    }
}
.complexColorPicker {
    >div {
        &:nth-child(2) {
            >div.sketch-picker {
                >div {
                    &:nth-child(3) {
                        display: none !important;
                    }
                }
            }
        }
    }
}
.widget-title-selector {
    margin-bottom: 0;
    display: flex;
}
.widget-title-area {
    height: 50px;
    border-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0;
    padding-top: 0;
    padding-bottom: 0;

    >.widget-title-description {
        display: flex;
        overflow: hidden;
        margin-bottom: 0;
        min-height: 18px;
    }
}
.react-grid-item.react-grid-placeholder {
    background-color: #676a6c;
}
.ibox-widget {
    clear: both;
    overflow: hidden;
    margin-top: 0;
    padding: 0;
    height: 100%;
}
.ibox-content-widget {
    background-color: #ffffff;
    color: inherit;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
    height: calc(100% - 50px);
    overflow: hidden;
}
.simple-widget-preview-border {
    border-bottom: 1px solid #e7eaec;
    border-right: 1px solid #e7eaec;
    border-top: 1px solid #e7eaec;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.widget-error {
    border: 2px solid red;
}

.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
}
.top-left {
    position: absolute;
    top: 50px;
    left: 16px;
}
.top-right {
    position: absolute;
    top: 50px;
    right: 16px;
}
.bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
}
.centered {
    margin-top: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.today-block {
    display: flex;
    flex: 2 1 auto;
    text-align: left;
    border-radius: 10px;
    margin-right: 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    background-color: #F0EEEE;
}
.today-block-label {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    padding: 0;
    >p {
        margin: 0;
    }
}
.today-block-value {
    text-align: center;
}
.trend-block {
    flex: 3;
    border-radius: 10px;
    font-size: 17px;
}
.historic-block {
    text-align: left;
    padding-bottom: 10px;
}
.historic-block-small {
    border-right: solid #7F7F7F;
    border-width: thin;
}
.current-block {
    display: flex;
    text-align: left;
    flex-direction: column;
    border-radius: 10px;
    padding-left: 20px;
    flex-grow: 1;
    margin: 0 10px;
    padding-bottom: 5px;
}
.widget-counter-container {
    display: flex;
    flex-direction: column;
    background-color: rgb(23, 161, 133);
    color: white;
    height: 100%;
    justify-content: stretch;
    align-content: stretch;
}
.widget-counter-symbol {
    height: 25%;
    >svg {
        width: 100%;
        height: 100%;
    }
}
.widget-counter-value {
    height: 35%;
    >svg {
        width: 100%;
        height: 100%;
    }
}
.widget-counter-type {
    height: 20%;
    >svg {
        width: 100%;
        height: 100%;
    }
}
.widget-counter-subType {
    height: 20%;
    >svg {
        width: 100%;
        height: 100%;
    }
}
.title-widget-text {
    font-size: 4rem;
    display: flex;
}
.title-widget-text.right {
    justify-content: flex-end;
}
.title-widget-text.left {
    justify-content: flex-start;
}
.title-widget-text.center {
    justify-content: center;
}
.title-widget-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.title-widget-border {
    border-bottom: 4px solid #e3e3e3;
    color: #afafaf;
    background-color: inherit;
    width: 100%;
    height: 1px;
}
.widget-list-view {
    margin: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    box-shadow: none !important;
    z-index: 0 !important;

    .fc-table {
        padding: 0!important;
    }
}
.map-widget-more-options {
    >div {
        >ul {
            >li {
                >a {
                    height: 50px;
                }
            }
        }
        >button {
            padding: 10px !important;
            border-right: 1px solid #e7eaec !important;
            margin-bottom: 0 !important;
        }
    }
    margin-right: 20px;
}
