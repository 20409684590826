.user-status-active {
    color: #19aa8d;
}

.user-status-inactive {
    color: #ed5565;
}

.user-form{
    padding: 20px;
    background-color: #edeeef;
    position: relative;
    z-index: 4;
}

.user-profile-form{
    background-color: white;
    z-index: 4;
    margin-bottom: 4rem;
    padding: 20px;
    position: relative;
}

.user-form .row-buffer{
    margin-top:20px
}

.user-form .row-buffer-plus{
    margin-top:40px
}

.user-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}