.react-rater {
    display: inline-block;
    line-height: normal;
    font-size: 4rem;
}
.react-rater > * {
    display: inline-block;
}
.react-rater-star {
    cursor: pointer;
    color: #ccc;
    position: relative;
}
.react-rater-star.will-be-active {
    color: #fc6;
}
.react-rater-star.is-active {
    color: #fc6;
}
.react-rater-star.is-active-half:before {
    color: #fc6;
    content: '\2605';
    position: absolute;
    left: 0;
    width: 50%;
    overflow: hidden;
}
.react-rater-star.is-disabled {
    cursor: default;
}

.animated-rater {
    transform: translateZ(0);
}
.animated-rater .react-rater > * {
    margin-left: 1rem;
}
.animated-rater svg {
    cursor: pointer;
}
