.dashboard-tab-header {
    min-width: 14%;
    text-align: center;
    font-size: 18px;
}
.active.dashboard-tab-header{
    border: none !important;
    border-bottom: 3px solid #84C13E !important;
}

.active.dashboard-tab-header > span {
    background-color: inherit !important;
    border: none !important;
}

.dashboard-tab-header  > a {
    margin-right: 0 !important;
}

.batch-details-panel-element {
}

.dashboard-tab-panel > div > ul{
    list-style-type:none;
    display:flex;
    justify-content: center;

}
.dashboard-tab-panel > div > ul li {
    display: list-item;
}

.dashboard-tab-panel > div > .tab-content {
    border: none;
}

.dashboard-tab-panel > div > .nav-tabs {
    border: none;
}

@media (max-width: 1100px) {
    .dashboard-tab-panel > div > .nav-tabs {
        margin-top: 0;
    }
}

.dashboard-tab-panel > div > div > div > .panel-body {
    background-color: #f3f3f4;
    padding: 20px 0 0 0 ;
}

.dashboard-tree-sectors {
    margin: 10px 27px 0 10px;
}

.dashboard-tree-panel-head {
    height: 60px;
}
.dashboard-tree-panel-head-font-weight {
    font-weight: 600;
}


.dashboard-tree-panel-head-icons-div {
    min-width: 56px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: stretch;
}

.dashboard-tree-panel-head-icons-row {
    padding-right: 15px;
    float: right;
}

.readings-chart-title {
    color: #1976D2;
    margin-bottom: -25px;
}

.readings-chart-search-box {
    margin: 20px -20px;
    border-top: 1px solid #e7eaec;
    background-color: #ffffff;
}

.readings-chart-search-box > div > .list-searchbox-row {
    padding-left: 20px;
}

.readings-chart-chart {
    display: flex;
    margin: 0;
    padding: 0 20px 20px;
    width: 100%;
}

@media (max-width: 500px) {
    .panel-body,
    .tabs-container .panel-body {
        padding: 5px !important;
    }

    .dashboard-tree-sectors {
        margin: 10px 0 0 10px;
    }
}

.react-grid-item.react-draggable.cssTransforms.react-resizable {
    box-shadow: 0 0 10px 0 rgba(155, 155, 155, 0.3);
}

