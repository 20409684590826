.audit-form {
    background-color: white;
    z-index: 4;
    margin-bottom: 4rem;
    padding: 20px;
    position: relative;
}

.entityTitle {
    font-size: 24px;
    font-weight: 300;
    text-align: left;
    color: #676a6c;
}
