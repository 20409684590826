.edit-widgets-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    margin-right: 10px;
}

.lock-icon-toggle {
    text-decoration: inherit;
    color: white;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
}

.lock-icon-toggle-fullscreen {
    background-color: transparent !important;
    cursor: pointer;
}

.lock-icon-helper-text {
    margin-right: 10px;
}

.dashboard-options {
    display: flex;
    float: right;
}

.scroll-up{
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s linear 0s, visibility;
    position: fixed;
    bottom: 50px;
    right: 0px;
    cursor: pointer;
 }

.fullscreen {
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    padding: 0 !important;
    position: absolute !important;
    z-index: 99999999999 !important;
}

.fullscreen-not-absolute {
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 130px 0 0 !important;
    padding: 0 !important;
    z-index: 99999999999 !important;
}

.fullscreen-logo-box {
    padding-bottom: 10px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: #2f4050;
}

.fullscreen-logo {
    width: 325px;
    margin-left: 20px;
    margin-top: 15px;
}

.fullscreen-dashboard-name {
    margin-top: 15px;
    margin-left: 20px;
    color: white;
    width: 100%;
    display: flex;
}

.fullscreen-exit-btn {
    margin-top: 25px;
    margin-right: 36px;
}

.fullscreen-exit-btn-helper {
    margin-top: 25px;
    margin-right: 10px;
}

.fullscreen-dashboard-name > i {
    line-height: 1.75em !important;
}

.fullscreen-dashboard-name > h3 {
    margin-top: 6px !important;
}

.max-widget-msg {
    text-align: left;
    display: flex;
    align-items: center;
    float: left;
    padding: 10px 25px 10px 10px;
    margin-left: 10px;
}

.widget_area_lock_container {
    justify-content: flex-end;
    padding: 10px
}

.widget_area_info {
     display: flex;
     justify-content: flex-start;
     align-items: center;
 }

.widget_area_info_text {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.widget_area_info_text > p {
    margin-left: 1rem;
    margin-bottom: 0;
}

.widget_area_action_btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}