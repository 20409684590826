.terminals-list {

  .terminal-status-active {
    color: #19aa8d;
  }

  .terminal-status-inactive {
    color: #ed5565;
  }

  .terminal-discovery-active {
    color: #19aa8d;
  }

  .terminal-discovery-inactive {
    color: #ed5565;
  }

}