
.switch-field::before{
    background-color: #969799 !important;
}

.switch-field-parent{
    display: table;
}

.switch-field-child{
    display:table-cell;
    vertical-align:middle
}