.star-full:before {
    display: inherit;
    content: "\2605";
     font-size: 20px;
}

.star-empty:before {
    display: inherit;
    content: "\2606";
    visibility: visible;
    font-size: 20px;
}

.star-full,
.star-empty {
    padding: 0px, 20px;
}

.rct-title-sidePanel  {
    width: 100%;
    font-size: 14px;
    padding: 0 5px;
}

.rct-text-sidePanel-blur {
    cursor: default;
    position: relative;
    padding: 6px 15px;
    font-size: 20px;
    border: 3px solid #FFF;
    background: rgb(249, 250, 250);
    display: flex;
    align-items: center;
}

.rct-text-sidePanel-blur > span {
    opacity: 0.5;
}

.rct-text-sidePanel {
    position: relative;
    padding: 6px 15px;
    font-size: 20px;
    border: 3px solid #FFF;
    background: rgb(249, 250, 250);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.rct-checkbox {
    width: 40px;
}

