.tr-even {
    background-color: #f9f9f9 !important;
}

.tr-odd {
    background-color: white !important;
}

.tr-even-hover:hover {
    background-color: #EEEFF0 !important;
}

.tr-odd-hover:hover {
    background-color: #EEEFF0 !important;
}

table > tbody > tr > td:nth-child(1) {
    vertical-align: middle;
}

table td{
    white-space: normal !important;
    word-wrap: break-word;
    vertical-align: middle !important;
    outline: none;
}

table th{
    white-space: normal !important;
    word-wrap: break-word;
    vertical-align: middle !important;
}

.checkbox-personalized * {
    box-sizing: border-box;
}
.checkbox-personalized input[type=checkbox] {
    display: none;
}
.checkbox-personalized label {
    margin: 0 auto;
    display: block;
    padding: 5px 0;
}
.checkbox-personalized label .check {
    margin: 3px 0;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 2px;
    text-align: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.checkbox-personalized label .check-disabled {
    cursor: not-allowed !important;
}

.checkbox-personalized input[type=checkbox]:checked + label .check {
    background: #1A7350;
    transition: all 0.2s ease-in;
    border: 1px solid #1A7350;
}
.checkbox-personalized input[type=checkbox]:checked + label .check:after {
    content: '';
    display: block;
    width: 5px;
    height: 11px;
    border: solid #FFF;
    margin: 2px 7px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.checkbox-personalized input[type=checkbox]:indeterminate + label .check {
    background: #939ba2;
    transition: all 0.2s ease-in;
    border: 1px solid #939ba2;
}
.checkbox-personalized input[type=checkbox]:indeterminate + label .check:after {
    content: '';
    display: block;
    height: 11px;
    border: solid #FFF;
    margin: 2px 7px;
    border-width: 0 2px 0 0;
    transform: rotate(90deg);
}

.custom-select-body-class > tbody > tr > td:first-child {
    padding: 0 !important;
}

.custom-select-header-class > thead > tr > th:first-child {
    padding: 0 !important;
}

@media (min-width: 200px)
and (max-width: 1512px) {
    .list-table table td, table th{
        overflow: visible;
        white-space: unset;
        text-overflow: clip;
        vertical-align: middle !important;
        overflow-wrap: break-word;
    }
}

@media (min-width: 1512px) {
    .list-table table td, table th{
        white-space: inherit;
        text-overflow: ellipsis;
        vertical-align: middle !important;
        overflow-wrap: break-word;
        overflow: overlay;
    }

    .list-body-container {
        overflow: visible;
    }
}

.data-table-total-entries-right {
    display: flex;
    flex-direction: row-reverse;
}

.row-fix-height {
    height: 1px;
}

.column-fix-height {
    padding: 0 !important;
    height: 53px;
}

.table-column-td-div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    word-break: break-word;
}

@-moz-document url-prefix() {
    .table-column-td-div {
        height: unset;
    }
}

.table-hover td > a {
    color: rgb(103, 106, 108) !important;
}

.table-hover > td > a:hover {
    color: rgb(103, 106, 108) !important;
}

.table-column-td-div > span {
    padding: 8px !important;
}

@media (min-width: 200px) and (max-width: 992px) {
    .table-column-td-div {
        display: block;
        height: auto;
        padding: 8px;
    }
}