.rounded-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  position: relative;

  border-radius: 20px;

  /* Button */

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.0125em;

  /* Neutral / 0 */

  color: #FFFFFF;
  border: 0;

  &:disabled {
    background: none;
    cursor: not-allowed;
    fill: #cccccc;
    color: #ccc;
    border: 1px solid #ccc;
  }

  &:disabled:hover {
    cursor: not-allowed;
  }
}

.rounded-btn-success {
  background-color: #1976D2;
  border-color: #1976D2;
  color: #FFFFFF;
  white-space: nowrap;

  &:hover {
  background: #1564C0;
  }

  &:disabled {
    background: none;
    cursor: not-allowed;
    fill: #cccccc;
    color: #ccc;
    border: 1px solid #ccc;
  }

  &:disabled:hover {
    cursor: not-allowed;
  }

}

.rounded-btn-cancel {
  background-color: white;
  border: 1px solid #e7eaec;
  color: inherit;
  white-space: nowrap;

  &:hover {
    border: 1px solid #d2d2d2;
  }

  &:disabled {
    background: none;
    cursor: not-allowed;
    fill: #cccccc;
    color:  #cacaca;
    border: 1px solid #ccc;
  }

  &:disabled:hover {
    cursor: not-allowed;
  }

}

