
.api-form {
    padding: 20px;
    background-color: #edeeef;
    position: relative;
    z-index: 4;
}

.api-terminal-list {
    display: inline-block;
}

.api-list-container {
    background-color: white;
}

.grey-hr-line-solid {
    border-bottom: 1px solid #9e9e9e;
    background-color: rgba(0, 0, 0, 0);
}

.api-terminals-select-all {
    z-index: 100;
    position: absolute;
    margin-top: 3px;
    margin-right: 33px;
    right: 0;
    width: 182 pxpx;
    padding: 6px 12px;
}

.api-terminals-select-all > span {
    padding-right: 15px;
    font-size: 13px;
    color: #676a6c;
    font-weight: bold;
}

.row-15px-height {
    height: 15px;
}