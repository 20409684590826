.icon-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  margin: 0 5px;

  position: relative;

  /* Primary/700 */

  background: #1976D2;
  border-radius: 20px;

  /* Button */

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.0125em;

  /* Neutral / 0 */

  color: #FFFFFF;
  border: 0;

  img{
    width: 20px;
    height: 20px;
    left: 19px;
    top: 9px;
    color: #FFFFFF;
  }

  &:hover {
    background: #1564C0;

    img {
      background-color: #1564C0;
    }
  }

  &:disabled {
    background: none;
    cursor: not-allowed;
    fill: #cccccc;
    color: #ccc;
    border: 1px solid #ccc;

    img {
      background: none;
    }
  }

  &:disabled:hover {
    cursor: not-allowed;
  }
}